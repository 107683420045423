<template>
    <div class="dash-container">
        <h1>
        CONTACT REQUESTS
        </h1>
        
        <hr style="margin: 5px 0px;border: 1px solid #e3e3e3;">
        <div class="requests">
        <span v-if="emsg && !smsg" class="error-message">{{ emsg }}</span>
        <b-table class="requests" 
          striped 
          default-sort-direction="asc" 
          default-sort="name" pack="fa" 
          sort-icon="magnify" 
          sort-icon-size="is-small"
          paginated
          per-page="5"
          :data="data"
        >
          <template v-for="column in columns">
            <b-table-column :key="column.id" v-bind="column">
                <template
                    v-if="column.searchable && !column.numeric"
                    slot="searchable"
                    slot-scope="props">
                    <b-input
                        v-model="props.filters[props.column.field]"
                        style="padding-left: 0px;"
                        placeholder="Search..."
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row[column.field] }}
                    <div v-if="column.field === 'response'">
                      <button class='button mini-button is-primary is-outlined is-success' :id='"accept-" + props.index' alt="Accept" v-on:click="acceptRequest(props.row.id, props.index)"><b-icon pack="fa" icon="check"></b-icon></button>
                      <button class='button mini-button is-primary is-outlined is-danger' :id='"deny-" + props.index' alt="Deny" v-on:click="denyRequest(props.row.id, props.index)"><b-icon pack="fa" icon="remove"></b-icon></button>
                    </div>
                </template>
            </b-table-column>
          </template>
        </b-table>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      smsg: "",
      emsg: "",
      data: [],
      columns: [
        {
            field: 'name',
            label: 'Name',
            width: 100,
            searchable: true,
        },
        {
            field: 'email',
            label: 'E-Mail',
            width: 100,
            searchable: true,
        },
        {
            field: 'lock',
            label: 'Lock',
            width: 80,
        },
        {
            field: "device",
            label: "Device",
            width: 50
        },
        {
            field: "response",
            label: "Response",
            width: 50,
        }
      ]
    }
  },
  watch: {
    '$props':{
      handler: function (val, oldVal) { 
        console.log('watch', val);
        console.log('watch', oldVal);
        this.message="";
        this.getData();
      },
      deep: true
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        var query = this.q;
        console.log("API: " + api);
        console.log("Searching: " + query);
        this.loading = true;

        this.$store.dispatch('auth/get_requests', query).then(
        (response) => {
            console.log(response);
            if (response.data.status == true && response.data.data != "" ) {
                console.log("if loop: ");
                console.log(response.data.data);
                this.data = response.data.data;
            } else {
              this.emsg = "You have no requests..."
              console.log("You have no requests");
              this.data = response.data.data;
            }
        },
        error => {
            this.loading = false;
            this.data = [];
            this.emsg = "You have no requests...";
            console.log(error);
        });

      });
    },
    acceptRequest(uid, row) {
      this.rmsg = "";
      this.emsg = "";
      requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        console.log("API: " + api);
        console.log("Accepting: " + uid);
        this.loading = true;

        this.$store.dispatch('auth/accept_req', uid).then(
        (response) => {
            console.log(response.status);
            if (response.status == true) {
                this.smsg = "Accepted";
                var d = document.getElementById("deny-" + row)
                var a = document.getElementById("accept-" + row)
                d.parentNode.removeChild(d);
                a.innerHTML="<span>Accepted</span>";
                a.disabled = true;
            } else {
              this.emsg = response.message
              console.log(response.message);
            }
        },
        error => {
            this.emsg = "Server Error...";
            console.log(error);
        });
        this.loading = false;
      });
    },
    denyRequest(uid, row) {
        this.smsg = "";
        this.emsg = "";
        requestAnimationFrame(() => {
        var api = process.env.VUE_APP_API;
        console.log("API: " + api);
        console.log("Denying: " + uid);
        this.loading = true;

        this.$store.dispatch('auth/remove_req', uid).then(
        (response) => {
            console.log(response.status);
            if (response.status == true) {
                this.smsg = "Rejected";
                var a = document.getElementById("accept-" + row)
                var d = document.getElementById("deny-" + row)
                a.parentNode.removeChild(a);
                d.innerHTML="<span>Rejected</span>";
                d.disabled = true;
            } else {
              this.emsg = "Server Error..."
              console.log("Server Error...");
            }
        },
        error => {
            this.emsg = "Server Error...";
            console.log(error);
        });
        this.loading = false;
      });

    }
  }
}
</script>
<style lang='scss' scoped>
h1 {
    font-weight: bold;
}
.requests {
  padding-top: 30px;
}
.remove a {
  color: red;
}
.error-message {
  color: red;
  text-align: center;
}
.success-message {
  color: #aac734;
  text-align: right;
}
.mini-button {
  padding: 10px;
  margin-left: 5px;
  height: 15px;
}
</style>